<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          ref="form"
          :model="formInline"
          class="demo-form-inline"
        >
          <!-- <el-form-item label="计费规则">
            <el-select filterable v-model="formInline.feeId" placeholder="请选择">
              <el-option label="全部"  value=""></el-option>
              <el-option
			    :label="value.feeName"
                :value="value.feeId"
                v-for='value in feeList'
                :key='value.feeCode'>
			   </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Vehicle_ype')">
            <el-select v-model="formInline.carType" placeholder="请选择">
              <el-option value="" label="全部">全部</el-option>
			  <el-option :value="0" label="小型车">小型车</el-option>
			  <el-option :value="2" label="大型车">大型车</el-option>
            </el-select>
          </el-form-item> -->

          <!--          <el-form-item label="车辆组">
            <el-select filterable v-model="formInline.parkCarGroupId" placeholder="请选择">
              <el-option value="" label="全部">全部</el-option>
              <el-option
			    :label="value.parkCarGroupName"
                :value="value.parkCarGroupId"
                v-for='value in carGroupList'
                :key='value.parkCarGroupId'>
			  </el-option>
            </el-select>
          </el-form-item>	-->

          <el-form-item label="">
            <!-- <el-button
			  type="primary"
              icon="el-icon-search"
              :loading="loading"
			  v-if='authority.button.query'
			  @click="page=1;searchData()"
			 >
			  查询
			 </el-button> -->
            <el-button
              type="primary"
              icon="el-icon-plus"
              v-if="authority.button.add"
              @click="addBerth"
            >
              添加
            </el-button>
          </el-form-item>
        </el-form>
      </div>

      <!--列表区域-->
      <div class="tableWrapper">
        <el-table
          fit
          header-cell-class-name="header-call-style"
          border
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <!-- <el-table-column type="selection" width="55"></el-table-column> -->
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="同步状态"
            width=""
            v-if="details.parkSystemType != 0 && details.parkAccessType != 3"
            align="center"
          >
            <template slot-scope="scope">
              <span :class="scope.row.syncStatus | colorFilter">{{
                scope.row.syncStatus | stateFilter
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="描述" width="" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.description }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('searchModule.Update_time')" width="" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.updatedTime }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="80"
            v-if="authority.button.edit || authority.button.delete"
          >
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="authority.button.edit" command="a">编辑</el-dropdown-item>
                  <el-dropdown-item v-if="authority.button.delete" command="b"
                    >删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top: 10px">
          <el-button
            v-if="authority.button.sync && details.parkAccessType != 3"
            type="primary"
            @click="updateData()"
            :loading="updateLoading"
            :disabled="tableData.length == 0"
            >同步本地</el-button
          >
        </div>
        <add-berth ref="addBerth" @searchData="searchData"></add-berth>
        <edit-berth ref="editBerth" @searchData="searchData"></edit-berth>
      </div>
    </div>
  </div>
</template>

<script>
import addBerth from "./add";
import editBerth from "./edit";
import // dateFormat
"../../../../../common/js/public.js";
export default {
  name: "chargeIndex",
  props: {
    authority: {
      type: Object,
      default: function () {
        return {};
      },
    },
    details: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  filters: {
    stateFilter(state) {
      return state == 1 ? "同步成功" : "同步失败";
    },
    colorFilter(state) {
      return state == 1 ? "color_1" : "color_2";
    },
  },
  data() {
    return {
      index: 0,
      total: 0,
      pageSize: 15,
      page: 1,
      loading: false,
      detailFlag: false,
      feeList: [],
      carGroupList: [],
      tableCols: [
        {
          prop: "debtLeaveRuleName",
          label: this.$t("list.Rule_Name"),
          width: "",
        },
        {
          prop: "debtLeaveRuleCode",
          label: "规则编码",
          width: "",
        },
        // {
        //   prop: 'carTypeContent',
        //   label: '车辆类型',
        //   width: ''
        // },
        // {
        //   prop: 'parkCarGroupName',
        //   label: '车辆组',
        //   width: ''
        // }
      ],
      tableData: [],
      formInline: {
        feeId: "",
        carType: "",
        parkCarGroupId: "",
      },
      updateLoading: false,
    };
  },
  methods: {
    // 添加
    addBerth() {
      this.$refs.addBerth.open();
    },
    // 查询计费规则
    getFeeList() {
      let url = "/acb/2.0/bacb/msfee/listByOperationId";
      this.$axios
        .get(url, {
          data: {
            operationId: this.$route.query.operationId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.feeList = res.value || [];
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 查询车辆组
    getCarGroupList() {
      let url = "/acb/2.0/bacb/msfeeScene/listParkCarGroup";
      this.$axios
        .get(url, {
          data: {
            parkId: this.$route.query.parkId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.carGroupList = res.value || [];
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },

    // 操作菜单项
    handleCommand(cmd, data) {
      if (cmd == "a") {
        this.$refs.editBerth.getDetail(data.debtLeaveRuleId);
        this.$refs.editBerth.open();
      }
      if (cmd == "b") {
        this.deleteCharge(data);
      }
    },

    // 删除
    deleteCharge({ parkDebtLeaveRuleId }) {
      this.$confirm("此操作将删除该欠费配置, 是否继续?", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let url = "/acb/2.0/bacb/parkDebtLeaveRule/delete/" + parkDebtLeaveRuleId;
          this.$axios.post(url).then((res) => {
            if (res.state == 0) {
              this.$alert("删除成功！", this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
              this.page = 1;
              this.getFeeList();
              // this.getCarGroupList();
              this.searchData();
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },

    // 搜索
    searchData() {
      this.loading = true;
      let parms = {};
      parms.feeId = this.formInline.feeId;
      parms.carType = this.formInline.carType;
      parms.parkCarGroupId = this.formInline.parkCarGroupId;
      // parms.parkCarGroupId = this.formInline.parkCarGroupId.join(',');
      let url = "/acb/2.0/bacb/parkDebtLeaveRule/getById/" + this.$route.query.parkId;
      this.$axios
        .get(url, {
          data: {
            // parkId: this.$route.query.parkId,
            // ...parms
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            let obj = res.value;
            if (res.value) {
              this.$axios
                .get("/acb/2.0/bacb/debtLeaveRule/getById/" + res.value.debtLeaveRuleId)
                .then((res1) => {
                  if (res1.state == 0) {
                    Object.assign(obj, res1.value);
                    this.tableData = [obj];
                  }
                });
            } else {
              this.tableData = [];
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    // 同步本地
    updateData() {
      this.updateLoading = true;
      this.$axios
        .get("/acb/2.0/bacb/msfeeScene/sync", {
          data: {
            parkId: this.$route.query.parkId,
          },
        })
        .then((res) => {
          this.updateLoading = false;
          if (res.state == 0) {
            this.$alert(res.value, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            }).then(() => {
              this.page = 1;
              this.getFeeList();
              this.searchData();
            });
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        })
        .catch((error) => {
          this.updateLoading = false;
          this.page = 1;
          this.getFeeList();
          this.searchData();
        });
    },
    // 初始化
    init() {
      this.$refs.form && this.$refs.form.resetFields();
      this.getFeeList();
      // this.getCarGroupList()
      this.searchData();
    },
  },
  beforeDestroy() {},
  components: {
    addBerth,
    editBerth,
  },
  created() {
    // console.log('计费规则:' + JSON.stringify(this.authority))
  },
  computed: {},
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  height 35px
.content

  overflow hidden
  .searchWrapper
    overflow hidden
    padding 22px 22px 0
    margin-bottom 20px
  .pagerWrapper
    text-align right
    margin-top 28px
    font-size 12px
    .export
      font-size 12px
      .iconfont
        margin-right 0
.detail
  width 61.4%
  height 82.9%
  position absolute
  top 8.6%
  left 20.1%
  background #FFFFFF
  box-shadow 0 0 4px 1px rgba(128, 145, 165, 0.3)
  border-radius 3px
  z-index 1000
  box-sizing border-box
  padding 20px 0
  .title
    position absolute
    background #3F4A56
    border-radius 3px 3px 0 0
    color #fff
    width 100%
    top 0
    left 0
    height 40px
    line-height 40px
    font-size 14px
    padding 0 12px
    box-sizing inherit
    z-index 100
    .el-icon-close
      float right
      margin-top 14px
  .info
    margin-top 20px
    padding 2.1%
    height 100%
    overflow auto
    box-sizing inherit
.mask
  background rgba(49, 53, 65, 0.6)
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 999
.el-dropdown-link
  cursor pointer
.color_1
  color #333333
.color_2
  color #D9001B
</style>
