var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加入场开闸方式",
            visible: _vm.dialogVisible,
            width: "700",
            "close-on-click-modal": false,
            "custom-class": "dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.resetForm,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { padding: "0" },
              attrs: {
                model: _vm.form,
                "label-width": "120px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "车辆组:", prop: "CarGroupIds" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "400px" },
                      attrs: {
                        placeholder: "请选择",
                        multiple: "",
                        "collapse-tags": "",
                      },
                      model: {
                        value: _vm.form.CarGroupIds,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "CarGroupIds", $$v)
                        },
                        expression: "form.CarGroupIds",
                      },
                    },
                    _vm._l(_vm.carGroupList, function (value) {
                      return _c("el-option", {
                        key: value.parkCarGroupId,
                        attrs: {
                          label: value.parkCarGroupName,
                          value: value.parkCarGroupId,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "通道名称:", prop: "ChannelIds" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择",
                        multiple: "",
                        "collapse-tags": "",
                      },
                      model: {
                        value: _vm.form.ChannelIds,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "ChannelIds", $$v)
                        },
                        expression: "form.ChannelIds",
                      },
                    },
                    _vm._l(_vm.channelList, function (value) {
                      return _c("el-option", {
                        key: value.parkChannelId,
                        attrs: {
                          label: value.parkChannelName,
                          value: value.parkChannelId,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "开闸方式:", prop: "openGateType" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.openGateType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "openGateType", $$v)
                        },
                        expression: "form.openGateType",
                      },
                    },
                    [
                      _c(
                        "el-option",
                        { attrs: { value: 1, label: "自动开闸" } },
                        [_vm._v("自动开闸")]
                      ),
                      _c(
                        "el-option",
                        { attrs: { value: 2, label: "不开闸" } },
                        [_vm._v("不开闸")]
                      ),
                      _vm.details.parkSystemType != 0 &&
                      _vm.details.parkAccessType != 3
                        ? _c(
                            "el-option",
                            { attrs: { value: 3, label: "确认开闸" } },
                            [_vm._v("确认开闸")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "起止时间:", required: "" } },
                [
                  _c(
                    "span",
                    { staticStyle: { display: "inline-block" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "startTime" } },
                        [
                          _c("el-time-picker", {
                            staticStyle: { width: "150px" },
                            attrs: {
                              "arrow-control": "",
                              placeholder: "开始时间",
                            },
                            model: {
                              value: _vm.form.startTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "startTime", $$v)
                              },
                              expression: "form.startTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticStyle: { display: "inline-block", margin: "0 7px" },
                    },
                    [_vm._v("－")]
                  ),
                  _c(
                    "span",
                    { staticStyle: { display: "inline-block" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "endTime" } },
                        [
                          _c("el-time-picker", {
                            staticStyle: { width: "150px" },
                            attrs: {
                              "arrow-control": "",
                              placeholder: "结束时间",
                            },
                            model: {
                              value: _vm.form.endTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "endTime", $$v)
                              },
                              expression: "form.endTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注:" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "330px" },
                    attrs: {
                      type: "textarea",
                      placeholder: "",
                      "auto-complete": "off",
                      maxlength: "200",
                      rows: 4,
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.form.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "description", $$v)
                      },
                      expression: "form.description",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.saveLoading },
                  on: { click: _vm.save },
                },
                [_vm._v(_vm._s(_vm.$t("button.preservation")))]
              ),
              _c("el-button", { on: { click: _vm.resetForm } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }