var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加LED显示文案",
            visible: _vm.dialogVisible,
            width: "500",
            "close-on-click-modal": false,
            "custom-class": "dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.resetForm,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "formContent",
              staticStyle: { padding: "0" },
              attrs: {
                rules: _vm.rules,
                model: _vm.form,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "业务场景:", prop: "sceneType" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "330px" },
                      attrs: { placeholder: "请选择" },
                      on: { change: _vm.getSceneName },
                      model: {
                        value: _vm.form.sceneType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "sceneType", $$v)
                        },
                        expression: "form.sceneType",
                      },
                    },
                    _vm._l(_vm.typeList, function (value) {
                      return _c("el-option", {
                        key: value.sceneType,
                        attrs: {
                          label: value.sceneName,
                          value: value.sceneType,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "LED显示文案:", prop: "ledContent" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "330px" },
                    attrs: {
                      type: "textarea",
                      placeholder: "",
                      "auto-complete": "off",
                      maxlength: "200",
                      rows: 6,
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.form.ledContent,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "ledContent", $$v)
                      },
                      expression: "form.ledContent",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.saveLoading },
                  on: { click: _vm.save },
                },
                [_vm._v(_vm._s(_vm.$t("button.preservation")))]
              ),
              _c("el-button", { on: { click: _vm.resetForm } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }