<template>
    <div>
        <el-dialog
		  title="添加LED显示文案"
		  :visible.sync="dialogVisible"
		  width="500"
		  :close-on-click-modal='false'
		  custom-class='dialog'
		  @close='resetForm'
		 >
            <el-form ref="form" :rules="rules" :model="form" label-width="120px" style='padding:0' class="formContent">
               <el-form-item label="业务场景:" prop="sceneType">
					<el-select v-model="form.sceneType" placeholder="请选择" style="width:330px" @change="getSceneName">
						<el-option
						  :label="value.sceneName"
						  :value="value.sceneType"
						  :key="value.sceneType"
						  v-for="value in typeList">
						</el-option>
					</el-select>
			   </el-form-item>
               <el-form-item label="LED显示文案:" prop="ledContent">
					<el-input
					  v-model="form.ledContent"
					  type="textarea"
					  placeholder=""
					  auto-complete="off"
					  maxlength="200"
					  :rows="6"
					  show-word-limit
					  style="width:330px"
					/>
			   </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button type="primary" @click="save" :loading="saveLoading">{{ $t('button.preservation') }}</el-button>
                <el-button @click="resetForm">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
   data () {
       return {
           dialogVisible: false,
           form: {
               sceneType: '',
			   ledContent: ''
           },
		   rules: {
		   		sceneType: [
					{ required: true, message: '请选择业务场景', trigger: 'change' }
				],
				ledContent: [
					{ required: true, message: '请填写文案', trigger: 'blur' }
				]
		   },
		   // 业务场景列表数据
		   typeList: [],
		   sceneName: '',
		   saveLoading: false
       }
   },
   methods: {
       // 打开窗口
       open () {
           this.dialogVisible = true;
		   for (let i of Object.keys(this.form)) {
				 this.form[i] = '';
		   }
           this.getTypeData();
       },

	   // 查询业务场景描述
	   getSceneName (val) {
	        try {
				this.typeList.forEach(item => {
					if (item.sceneType == val) {
						this.sceneName = item.sceneName;
						this.form.ledContent = item.ledContent || '';
						throw new Error("breakForEach");
					}
				});
			} catch (e) {
				if (e.message != "breakForEach") throw e;
			}
	   },

	   // 保存
       save () {
            this.$refs.form.validate((valid) => {
			    if (valid) {
				    let parms = {}
				    this.saveLoading = true;
					parms.sceneType = this.form.sceneType;
					parms.sceneName = this.sceneName;
					parms.ledContent = this.form.ledContent;
					this.$axios.post('/acb/2.0/bacb/ledConfig/add', {
						data: {
							parkId: this.$route.query.parkId,
							...parms
						}
					}).then(res => {
						if (res.state == 0) {
						    setTimeout(() => {
						        this.saveLoading = false;
								this.$emit('searchData');
								this.dialogVisible = false;
							}, 2000)
						} else {
						    this.saveLoading = false;
							this.$alert(res.desc, this.$t('pop_up.Tips'), {
								confirmButtonText: this.$t('pop_up.Determine')
							})
						}
					}).catch(() => {
						this.saveLoading = false;
					})
				} else {
					return false;
				}
			})
       },

      // 查询未配置业务场景
      getTypeData () {
        this.typeList = [];
         this.$axios.get('/acb/2.0/bacb/ledConfig/unConfiguredScene', {
          data: {
		    parkId: this.$route.query.parkId
          }
        }).then(res => {
          if (res.state == 0) {
            if (res.value) {
//			   this.typeList = [
//				{
//					sceneName: '业务场景1',
//					sceneType: 1
//				},
//				{
//					sceneName: '业务场景2',
//					sceneType: 2
//				},
//				{
//					sceneName: '业务场景3',
//					sceneType: 3
//				}
//			  ]
			  this.typeList = res.value || [];
            }
          } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine')
              })
          }
        })
      },

	   // 取消
	   resetForm () {
		  this.dialogVisible = false;
		  this.$refs.form.resetFields();
	   }

   },
   created () {
   }
}
</script>
<style>
    .formWidth{
        width:221.5px;
    }

	.formContent .el-input__count{
	    right:5px;
		bottom: -35px!important;
		background:transparent!important;
	}
</style>

