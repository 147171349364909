<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content" v-loading="loading">
      <h2 class="title">
        <div class="title_icon"></div>
        出入场配置
      </h2>
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-width="240px"
        style="padding: 0"
      >
        <el-row type="flex" justify="center">
          <el-col :span="10">
            <el-form-item label="无牌车是否允许入场" prop="noPlateEntry">
              <el-radio-group v-model="form.noPlateEntry">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="车位已满时"
              prop="berthFullRule"
              v-if="!parkingSystemType"
            >
              <el-select v-model="form.berthFullRule" placeholder="请选择">
                <el-option value="" label="请选择">请选择</el-option>
                <el-option
                  :label="value.value"
                  :value="value.id"
                  :key="value.id"
                  v-for="value in parkFullList"
                ></el-option>
              </el-select>
            </el-form-item>
            <template v-if="details.parkAccessType == 3 && parkingSystemType">
              <el-form-item
                label="车位满时"
                class="itemWidth"
                prop="monthlyCarRentalEntry"
              >
                <el-select
                  v-model="form.monthlyCarRentalEntry"
                  placeholder="请选择"
                  @change="form.allowCarRentalEntry = []"
                >
                  <el-option label="允许部分车辆入场" :value="1"> </el-option>
                  <el-option label="禁止所有车辆入场" :value="0"> </el-option>
                </el-select>
                <el-tooltip
                  placement="top"
                  class="tooltip1"
                  style="top: 10px; left: 230px"
                >
                  <div slot="content">
                    当剩余固定泊位数已满后，如果剩余临停泊位数不为零，则月租车等占用固定泊位类型的车辆组入场时优先扣减剩余临停泊位数
                    <br />
                  </div>
                  <span class="help">?</span>
                </el-tooltip>
              </el-form-item>
              <el-form-item
                label=""
                class="itemWidth"
                prop="allowCarRentalEntry"
                v-if="form.monthlyCarRentalEntry === 1"
              >
                <el-select
                  v-model="form.allowCarRentalEntry"
                  placeholder="请选择"
                  multiple
                  collapse-tags
                >
                  <el-option
                    :label="value.parkCarGroupName"
                    :value="value.parkCarGroupId"
                    v-for="value in carGroup"
                    :key="value.parkCarGroupId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </template>
            <template v-else>
              <el-form-item
                label="车位满时是否允许月租车入场"
                prop="monthlyCarRentalEntry"
                v-if="parkingSystemType"
              >
                <el-radio-group v-model="form.monthlyCarRentalEntry">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </template>
            <!-- <el-form-item label="仅限会员车入场" prop="memberOnly" v-if="!parkingSystemType">
              <el-radio-group v-model="form.memberOnly">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item> -->
            <el-form-item
              label="单出场异常放行"
              prop="errorExitPass"
              v-if="!parkingSystemType"
            >
              <el-radio-group v-model="form.errorExitPass">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="场内缴费后免费离场时间"
              prop="innerPayFreeLeaveTime"
              v-if="parkingSystemType"
            >
              <el-input
                v-model="form.innerPayFreeLeaveTime"
                @input="($event) => timeNumber($event, 'innerPayFreeLeaveTime')"
                style="width: 120px; margin: 0 5px 0 0"
                maxlength="3"
              ></el-input
              >分钟
            </el-form-item>
            <el-form-item
              v-if="details.parkAccessType == 3"
              label="同一辆车入口识别间隔"
              prop="carRecognitionInterval"
            >
              <el-input
                v-model="form.carRecognitionInterval"
                @input="
                  ($event) => timeNumber($event, 'carRecognitionInterval')
                "
                style="width: 120px; margin: 0 5px 0 0"
                maxlength="600"
              ></el-input
              >秒
            </el-form-item>
            <!-- <el-form-item label="入场时车辆先放行客服后处理" prop="inFirstServiceNext" v-if="parkingSystemType && remote">
              <el-radio-group v-model="form.inFirstServiceNext">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item> -->
            <el-row>
              <el-col :span="10">
                <el-form-item
                  label="通过事件: 事件置信度≥"
                  prop="passEventReliabilityThreshold"
                >
                  <el-input
                    v-model="form.passEventReliabilityThreshold"
                    @input="
                      ($event) =>
                        timeNumber($event, 'passEventReliabilityThreshold')
                    "
                    style="width: 60px; margin: 0 5px 0 0"
                    maxlength="600"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item
                  label="车牌置信度≥"
                  prop="passPlateReliabilityThreshold"
                >
                  <el-input
                    v-model="form.passPlateReliabilityThreshold"
                    @input="
                      ($event) =>
                        timeNumber($event, 'passPlateReliabilityThreshold')
                    "
                    style="width: 60px; margin: 0 5px 0 0"
                    maxlength="600"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item
                  label="折返事件: 事件置信度≥"
                  prop="backEventReliabilityThreshold"
                >
                  <el-input
                    v-model="form.backEventReliabilityThreshold"
                    @input="
                      ($event) =>
                        timeNumber($event, 'backEventReliabilityThreshold')
                    "
                    style="width: 60px; margin: 0 5px 0 0"
                    maxlength="600"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item
                  label="车牌置信度≥"
                  prop="backPlateReliabilityThreshold"
                >
                  <el-input
                    v-model="form.backPlateReliabilityThreshold"
                    @input="
                      ($event) =>
                        timeNumber($event, 'backPlateReliabilityThreshold')
                    "
                    style="width: 60px; margin: 0 5px 0 0"
                    maxlength="600"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="14">
            <el-row>
              <el-col :span="24">
                <el-form-item
                  style="display: inline-block"
                  label="支持相似车牌出入场匹配"
                  prop="similarPlateMatch"
                >
                  <el-radio-group
                    v-model="form.similarPlateMatch"
                    @change="form.defaultProvince = ''"
                  >
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <!-- </el-col>
              <el-col :span="8"> -->
                <!-- 默认省份 -->
                <el-form-item
                  style="display: inline-block; margin-left: 10px; width: 190px"
                  label-width="90px"
                  label="默认省份"
                  prop="defaultProvince"
                  v-if="
                    form.similarPlateMatch === 1 && details.parkAccessType == 3
                  "
                >
                  <el-select
                    v-model="form.defaultProvince"
                    placeholder="请选择"
                  >
                    <el-option
                      :label="ite.value"
                      :value="ite.key"
                      :key="index"
                      v-for="(ite, index) in mrsfList"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="军警车是否计费" prop="policeCarFree">
              <el-radio-group v-model="form.policeCarFree">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="断网时是否允许车辆欠费离场"
              prop="noNetworkDeparture"
              v-if="0"
            >
              <el-radio-group v-model="form.noNetworkDeparture">
                <el-radio :label="2">是</el-radio>
                <el-radio :label="1">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="场内缴费后免费离场时间"
              prop="innerPayFreeLeaveTime"
              v-if="!parkingSystemType"
            >
              <el-input
                v-model="form.innerPayFreeLeaveTime"
                style="width: 120px; margin: 0 5px 0 0"
                :maxlength="3"
              ></el-input
              >分钟
            </el-form-item>
            <el-form-item
              label="记录缺失收费区域偏向"
              prop="childParkId"
              v-if="parkingSystemType"
            >
              <el-select v-model="form.childParkId" placeholder="请选择">
                <el-option
                  :label="value.parkName"
                  :value="value.parkId"
                  :key="value.parkId"
                  v-for="value in parkList"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="机器人事件是否人工核实"
              class="itemWidth"
              prop="cameraEventManualConfirm"
            >
              <el-radio-group v-model="form.cameraEventManualConfirm">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
              <el-tooltip
                placement="top"
                class="tooltip1"
                style="top: 10px; left: 230px"
              >
                <div slot="content">
                  对已购买车道感知机器人的车场生效，选择否则自动忽略图片欠佳的情况，选择是则需人工
                </div>
                <span class="help1">?</span>
              </el-tooltip>
            </el-form-item>
            <el-form-item
              label="是否自动补发抬杆指令"
              class="itemWidth"
              prop="autoOpen"
            >
              <el-radio-group v-model="form.autoOpen">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
              <el-tooltip
                placement="top"
                class="tooltip1"
                style="top: 10px; left: 230px"
              >
                <div slot="content">
                  下发抬杆指令，若闸机无反应或未完全抬起，系统自动再次发送抬杆命令，确保闸机正常抬起
                </div>
                <span class="help1">?</span>
              </el-tooltip>
            </el-form-item>
            <el-form-item
              label="是否自动落杆"
              class="itemWidth"
              prop="autoClose"
            >
              <el-radio-group v-model="form.autoClose">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
              <el-tooltip
                placement="top"
                class="tooltip1"
                style="top: 10px; left: 230px"
              >
                <div slot="content">
                  闸机意外抬起且车道无车时，系统自动下发落杆指令，保障停车场安全
                </div>
                <span class="help1">?</span>
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <el-col :span="14">
            <el-form-item>
              <!--v-if="syncStatus == 2 && authority.button.status"-->
              <template v-if="details.parkAccessType != 3">
                <el-button
                  type="danger"
                  plain
                  @click="updateData()"
                  :loading="updateLoading"
                  v-if="
                    syncStatus == 2 &&
                    authority.button.status &&
                    parkingSystemType != 0
                  "
                  >同步失败</el-button
                >
                <el-button
                  type="primary"
                  plain
                  @click="updateData()"
                  :loading="updateLoading"
                  v-if="
                    syncStatus == 1 &&
                    authority.button.status &&
                    parkingSystemType != 0
                  "
                  >同步成功</el-button
                >
                <el-button
                  type="primary"
                  @click="save"
                  :loading="saveLoading"
                  v-if="authority.button.addSync"
                  style="margin: 0 0 0 25px"
                  >{{
                    parkingSystemType != 0 ? "保存并同步" : "保存"
                  }}</el-button
                >
              </template>
              <template v-else>
                <el-button
                  v-if="authority.button.addSync"
                  type="primary"
                  @click="save"
                  :loading="saveLoading"
                  style="margin: 0 0 0 25px"
                  >{{ $t('button.preservation') }}</el-button
                >
              </template>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import { dateFormat } from "../../../../../common/js/public.js";
export default {
  name: "businessIndex",
  props: {
    authority: {
      type: Object,
      default: function () {
        return {
          button: {},
        };
      },
    },
    details: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      index: 0,
      total: 0,
      loading: false,
      form: {
        parkBusiParamId: "",
        noPlateEntry: "",
        berthFullRule: "",
        monthlyCarRentalEntry: "",
        allowCarRentalEntry: [],
        // memberOnly: "",
        innerPayFreeLeaveTime: "",
        carRecognitionInterval: "",
        errorExitPass: "",
        similarPlateMatch: "",
        defaultProvince: "", // 默认省份
        policeCarFree: "",
        exitAbnormalPass: "",
        childParkId: "",
        cameraEventManualConfirm: 0,
        autoOpen: 0, // 是否自动补发抬杆指令
        autoClose: 0, // 是否自动落杆
        passPlateReliabilityThreshold: "",
        passEventReliabilityThreshold: "",
        backPlateReliabilityThreshold: "",
        backEventReliabilityThreshold: "",
        innerPayable: "",
        unattendedState: "",
        // inFirstServiceNext: ""
        noNetworkDeparture: 1,
      },
      rules: {
        noPlateEntry: [
          {
            required: true,
            message: "请选择是否允许入场",
            trigger: "change",
          },
        ],
        // inFirstServiceNext: [
        //   { required: true, message: "请选择入场时处理", trigger: "change" },
        // ],
        berthFullRule: [
          {
            required: true,
            message: "请选择车位已满时",
            trigger: "change",
          },
        ],
        monthlyCarRentalEntry: [
          // { required: true, message: "请选择月租车入场", trigger: "change" }
          {
            required: true,
            message: "请选择月租车入场",
            trigger: "change",
          },
        ],
        allowCarRentalEntry: [
          { required: true, message: "请选择入场车辆组", trigger: "change" },
        ],
        // memberOnly: [
        //   { required: true, message: "请选择是否只限会员车入场", trigger: "change" },
        // ],
        innerPayFreeLeaveTime: [
          {
            required: true,
            message: "请输入免费离场时间",
            trigger: "blur",
          },
        ],
        carRecognitionInterval: [
          {
            required: true,
            message: "请输入同一辆车入口识别间隔",
            trigger: "blur",
          },
        ],
        similarPlateMatch: [
          {
            required: true,
            message: "请选择车牌出入场匹配",
            trigger: "change",
          },
        ],
        // 默认省份
        defaultProvince: [
          {
            required: true,
            message: "请选择相似车牌出入场匹配的默认省份",
            trigger: "change",
          },
        ],
        policeCarFree: [
          {
            required: true,
            message: "请选择军警车是否计费",
            trigger: "change",
          },
        ],
        noNetworkDeparture: [
          {
            required: true,
            message: "请选择断网时是否允许车辆欠费离场",
            trigger: "change",
          },
        ],
        exitAbnormalPass: [
          {
            required: true,
            message: "请选择单出场异常放行",
            trigger: "change",
          },
        ],
        childParkId: [
          {
            required: true,
            message: "请选择缺失收费区域偏向",
            trigger: "change",
          },
        ],
        cameraEventManualConfirm: [
          {
            required: true,
            message: "请选择机器人事件是否人工核实",
            trigger: "change",
          },
        ],
        autoOpen: [
          {
            required: true,
            message: "请选择自动补发抬杆指令",
            trigger: "change",
          },
        ],
        autoClose: [
          {
            required: true,
            message: "是否自动落杆",
            trigger: "change",
          },
        ],
        passPlateReliabilityThreshold: [
          {
            required: true,
            message: "请输入车牌置信度",
            trigger: "change",
          },
        ],
        passEventReliabilityThreshold: [
          {
            required: true,
            message: "请输入事件置信度",
            trigger: "change",
          },
        ],
        backPlateReliabilityThreshold: [
          {
            required: true,
            message: "请输入车牌置信度",
            trigger: "change",
          },
        ],
        backEventReliabilityThreshold: [
          {
            required: true,
            message: "请输入事件置信度",
            trigger: "change",
          },
        ],
      },
      // 记录缺失数据
      parkList: [],
      // 车位已满时
      parkFullList: [
        {
          id: 1,
          value: "不允许任何车辆入场",
        },
        {
          id: 2,
          value: "只允许月租车入场",
        },
        {
          id: 3,
          value: "允许任何车辆出入场",
        },
      ],
      updateLoading: false,
      saveLoading: false,
      syncStatus: "",
      // remote: "",
      parkingSystemType: "",
      carGroup: [], // 车位已满时下拉列表数据
      mrsfList: [
        {
          key: 1,
          value: "京",
        },
        {
          key: 2,
          value: "津",
        },
        {
          key: 3,
          value: "沪",
        },
        {
          key: 4,
          value: "渝",
        },
        {
          key: 5,
          value: "冀",
        },
        {
          key: 6,
          value: "晋",
        },
        {
          key: 7,
          value: "辽",
        },
        {
          key: 8,
          value: "黑",
        },
        {
          key: 9,
          value: "苏",
        },
        {
          key: 10,
          value: "浙",
        },
        {
          key: 11,
          value: "皖",
        },
        {
          key: 12,
          value: "闽",
        },
        {
          key: 13,
          value: "赣",
        },
        {
          key: 14,
          value: "鲁",
        },
        {
          key: 15,
          value: "豫",
        },
        {
          key: 16,
          value: "鄂",
        },
        {
          key: 17,
          value: "湘",
        },
        {
          key: 18,
          value: "粤",
        },
        {
          key: 19,
          value: "琼",
        },
        {
          key: 20,
          value: "川",
        },
        {
          key: 21,
          value: "贵",
        },
        {
          key: 22,
          value: "云",
        },
        {
          key: 23,
          value: "陕",
        },
        {
          key: 24,
          value: "甘",
        },
        {
          key: 25,
          value: "青",
        },
        {
          key: 26,
          value: "藏",
        },
        {
          key: 27,
          value: "桂",
        },
        {
          key: 28,
          value: "蒙",
        },
        {
          key: 29,
          value: "宁",
        },
        {
          key: 30,
          value: "新",
        },
        {
          key: 31,
          value: "黔",
        },
        {
          key: 32,
          value: "港",
        },
        {
          key: 33,
          value: "澳",
        },
        {
          key: 34,
          value: "台",
        },
        {
          key: 35,
          value: "使",
        },
        {
          key: 36,
          value: "无",
        },
        {
          key: 37,
          value: "领",
        },
        {
          key: 38,
          value: "军",
        },
        {
          key: 39,
          value: "警",
        },
        {
          key: 40,
          value: "临",
        },
        {
          key: 41,
          value: "学",
        },
        {
          key: 42,
          value: "挂",
        },
        {
          key: 43,
          value: "应",
        },
        {
          key: 44,
          value: "急",
        },
        {
          key: 45,
          value: "残",
        },
      ], // 默认省份
    };
  },
  methods: {
    // 获取车辆组列表
    getList() {
      this.$axios
        .get("/acb/2.0/bacb/parkCarGroup/parkCarGroupForBerth", {
          data: {
            parkId: this.$route.query.parkId,
            // type: 1,
          },
        })
        .then((res) => {
          this.carGroup = res.value || [];
        });
    },
    // 保存
    save() {
      this.$refs.form.validate((valid) => {
        console.log(1, valid);
        if (valid) {
          // console.log('提交分析:' + JSON.stringify(this.form))
          this.saveLoading = true;
          this.$axios
            .post("/acb/2.0/bacb/parkBusiParam/save", {
              data: {
                parkId: this.$route.query.parkId,
                ...this.form,
              },
            })
            .then((res) => {
              this.saveLoading = false;
              if (res.state == 0) {
                // if (res.value) {
                this.$alert("操作成功！", this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                }).then(() => {
                  this.searchData();
                });
                //  }
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            })
            .catch((error) => {
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },

    // 详情
    searchData() {
      for (let i in this.form) {
        this.form[i] = "";
      }
      let parkId = this.$route.query.parkId;
      let url = "/acb/2.0/bacb/parkBusiParam/getByParkId/" + parkId;
      this.loading = true;
      this.$axios
        .get(url)
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            if (JSON.stringify(res.value) !== "{}") {
              // console.log("A1");
              this.form.parkBusiParamId = res.value.parkBusiParamId;
              this.form.noPlateEntry = res.value.noPlateEntry;
              this.form.similarPlateMatch = res.value.similarPlateMatch || 0;
              this.form.defaultProvince = res.value.defaultProvince || ""; // 默认省份
              this.form.monthlyCarRentalEntry =
                res.value.monthlyCarRentalEntry || 0;
              // this.form.memberOnly = res.value.memberOnly;
              this.form.policeCarFree = res.value.policeCarFree || 0;
              this.form.berthFullRule = res.value.berthFullRule;
              this.form.errorExitPass = res.value.errorExitPass;
              this.form.exitAbnormalPass = res.value.exitAbnormalPass;
              this.form.innerPayFreeLeaveTime =
                res.value.innerPayFreeLeaveTime || 10;
              this.form.carRecognitionInterval =
                res.value.carRecognitionInterval || 1;
              this.form.childParkId = res.value.childParkId;
              this.form.cameraEventManualConfirm =
                res.value.cameraEventManualConfirm || 0;
              this.form.passPlateReliabilityThreshold =
                res.value.passPlateReliabilityThreshold || 90;
              this.form.passEventReliabilityThreshold =
                res.value.passEventReliabilityThreshold || 90;
              this.form.backPlateReliabilityThreshold =
                res.value.backPlateReliabilityThreshold || 90;
              this.form.backEventReliabilityThreshold =
                res.value.backEventReliabilityThreshold || 90;
              this.form.innerPayable = res.value.innerPayable;
              this.form.unattendedState = res.value.unattendedState;
              // this.form.inFirstServiceNext = res.value.inFirstServiceNext;
              this.syncStatus = res.value.syncStatus;
              this.parkingSystemType = res.value.parkSystemType;
              this.form.noNetworkDeparture = res.value.noNetworkDeparture || 1;
              this.form.allowCarRentalEntry =
                res.value.allowCarRentalEntry?.split(",") || [];
              // this.remote = res.value.remote;

              this.form.autoOpen = res.value.autoOpen;
              this.form.autoClose = res.value.autoClose;
            } else {
              // console.log("A2");
              this.$refs.form.resetFields();
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    // 查询车场列表
    getParkList() {
      this.carGroupList = [];
      let url = "/acb/2.0/bacb/park/children/" + this.$route.query.parkId;
      this.$axios
        .get(url, {
          data: {
            // parkId: this.$route.query.parkId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.parkList = res.value || [];
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },

    // 同步失败
    updateData() {
      this.updateLoading = true;
      this.$axios
        .post("/acb/2.0/bacb/parkBusiParam/sync", {
          data: {
            parkId: this.$route.query.parkId,
            parkBusiParamId: this.form.parkBusiParamId,
          },
        })
        .then((res) => {
          this.updateLoading = false;
          if (res.state == 0) {
            this.$alert(res.value, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            }).then(() => {
              this.searchData();
            });
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        })
        .catch((error) => {
          this.updateLoading = false;
        });
    },

    timeNumber(event, value) {
      event = event.replace(/[^\d]/g, "");
      event = event.replace(/^0/g, "");
      switch (value) {
        case "innerPayFreeLeaveTime":
          if (event > 60) {
            event = 60;
          }
          break;
        case "carRecognitionInterval":
          if (event > 600) {
            event = 600;
          }
          break;
        case "passPlateReliabilityThreshold":
          if (event > 100) {
            event = 100;
          }
          break;
        case "passEventReliabilityThreshold":
          if (event > 100) {
            event = 100;
          }
          break;
        case "backPlateReliabilityThreshold":
          if (event > 100) {
            event = 100;
          }
          break;
        case "backEventReliabilityThreshold":
          if (event > 100) {
            event = 100;
          }
          break;
        default:
          break;
      }

      this.form[value] = event;
    },

    // 初始化
    init() {
      this.getParkList();
      this.searchData();
      this.details.parkAccessType == 3 && this.getList();
    },
  },
  beforeDestroy() {
    // sessionStorage.searchData = JSON.stringify(this.formInline);
    // sessionStorage.searchFlag = 1;
  },
  components: {},
  created() {
    // console.log('业务参数分析:' + JSON.stringify(this.authority))
  },
  computed: {},
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  background: #FFFFFF;
  overflow: hidden;
  padding: 20px 20px 0;

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }

  .itemWidth {
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.el-dropdown-link {
  cursor: pointer;
}

.tooltip1 {
  position: absolute !important;
  right: 8px;
  top: -2px;
  z-index: 1;
}

.help, .help1 {
  display: inline-block;
  border-radius: 8px;
  text-align: center;
  color: white;
  width: 16px;
  line-height: 16px;
  vertical-align: middle;
  background: #c0ccda;
  position: relative;
}

.help1 {
  top: 12px !important;
  left: 113px !important;
}
</style>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
.el-form-item__error {
  min-width: 120px;
}
</style>
