var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page1" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "content",
      },
      [
        _vm._m(0),
        _c(
          "el-form",
          {
            ref: "form",
            staticStyle: { padding: "0" },
            attrs: {
              rules: _vm.rules,
              model: _vm.form,
              "label-width": "240px",
            },
          },
          [
            _c(
              "el-row",
              { attrs: { type: "flex", justify: "center" } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 10 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "无牌车是否允许入场",
                          prop: "noPlateEntry",
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.form.noPlateEntry,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "noPlateEntry", $$v)
                              },
                              expression: "form.noPlateEntry",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("是"),
                            ]),
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("否"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    !_vm.parkingSystemType
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "车位已满时",
                              prop: "berthFullRule",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                model: {
                                  value: _vm.form.berthFullRule,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "berthFullRule", $$v)
                                  },
                                  expression: "form.berthFullRule",
                                },
                              },
                              [
                                _c(
                                  "el-option",
                                  { attrs: { value: "", label: "请选择" } },
                                  [_vm._v("请选择")]
                                ),
                                _vm._l(_vm.parkFullList, function (value) {
                                  return _c("el-option", {
                                    key: value.id,
                                    attrs: {
                                      label: value.value,
                                      value: value.id,
                                    },
                                  })
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.details.parkAccessType == 3 && _vm.parkingSystemType
                      ? [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "itemWidth",
                              attrs: {
                                label: "车位满时",
                                prop: "monthlyCarRentalEntry",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  on: {
                                    change: function ($event) {
                                      _vm.form.allowCarRentalEntry = []
                                    },
                                  },
                                  model: {
                                    value: _vm.form.monthlyCarRentalEntry,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "monthlyCarRentalEntry",
                                        $$v
                                      )
                                    },
                                    expression: "form.monthlyCarRentalEntry",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      label: "允许部分车辆入场",
                                      value: 1,
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: "禁止所有车辆入场",
                                      value: 0,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "tooltip1",
                                  staticStyle: { top: "10px", left: "230px" },
                                  attrs: { placement: "top" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _vm._v(
                                        " 当剩余固定泊位数已满后，如果剩余临停泊位数不为零，则月租车等占用固定泊位类型的车辆组入场时优先扣减剩余临停泊位数 "
                                      ),
                                      _c("br"),
                                    ]
                                  ),
                                  _c("span", { staticClass: "help" }, [
                                    _vm._v("?"),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm.form.monthlyCarRentalEntry === 1
                            ? _c(
                                "el-form-item",
                                {
                                  staticClass: "itemWidth",
                                  attrs: {
                                    label: "",
                                    prop: "allowCarRentalEntry",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择",
                                        multiple: "",
                                        "collapse-tags": "",
                                      },
                                      model: {
                                        value: _vm.form.allowCarRentalEntry,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "allowCarRentalEntry",
                                            $$v
                                          )
                                        },
                                        expression: "form.allowCarRentalEntry",
                                      },
                                    },
                                    _vm._l(_vm.carGroup, function (value) {
                                      return _c("el-option", {
                                        key: value.parkCarGroupId,
                                        attrs: {
                                          label: value.parkCarGroupName,
                                          value: value.parkCarGroupId,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      : [
                          _vm.parkingSystemType
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "车位满时是否允许月租车入场",
                                    prop: "monthlyCarRentalEntry",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.form.monthlyCarRentalEntry,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "monthlyCarRentalEntry",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.monthlyCarRentalEntry",
                                      },
                                    },
                                    [
                                      _c("el-radio", { attrs: { label: 1 } }, [
                                        _vm._v("是"),
                                      ]),
                                      _c("el-radio", { attrs: { label: 0 } }, [
                                        _vm._v("否"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                    !_vm.parkingSystemType
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "单出场异常放行",
                              prop: "errorExitPass",
                            },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.form.errorExitPass,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "errorExitPass", $$v)
                                  },
                                  expression: "form.errorExitPass",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("是"),
                                ]),
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("否"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.parkingSystemType
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "场内缴费后免费离场时间",
                              prop: "innerPayFreeLeaveTime",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: {
                                width: "120px",
                                margin: "0 5px 0 0",
                              },
                              attrs: { maxlength: "3" },
                              on: {
                                input: ($event) =>
                                  _vm.timeNumber(
                                    $event,
                                    "innerPayFreeLeaveTime"
                                  ),
                              },
                              model: {
                                value: _vm.form.innerPayFreeLeaveTime,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "innerPayFreeLeaveTime",
                                    $$v
                                  )
                                },
                                expression: "form.innerPayFreeLeaveTime",
                              },
                            }),
                            _vm._v("分钟 "),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.details.parkAccessType == 3
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "同一辆车入口识别间隔",
                              prop: "carRecognitionInterval",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: {
                                width: "120px",
                                margin: "0 5px 0 0",
                              },
                              attrs: { maxlength: "600" },
                              on: {
                                input: ($event) =>
                                  _vm.timeNumber(
                                    $event,
                                    "carRecognitionInterval"
                                  ),
                              },
                              model: {
                                value: _vm.form.carRecognitionInterval,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "carRecognitionInterval",
                                    $$v
                                  )
                                },
                                expression: "form.carRecognitionInterval",
                              },
                            }),
                            _vm._v("秒 "),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 10 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "通过事件: 事件置信度≥",
                                  prop: "passEventReliabilityThreshold",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: {
                                    width: "60px",
                                    margin: "0 5px 0 0",
                                  },
                                  attrs: { maxlength: "600" },
                                  on: {
                                    input: ($event) =>
                                      _vm.timeNumber(
                                        $event,
                                        "passEventReliabilityThreshold"
                                      ),
                                  },
                                  model: {
                                    value:
                                      _vm.form.passEventReliabilityThreshold,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "passEventReliabilityThreshold",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.passEventReliabilityThreshold",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 10 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "车牌置信度≥",
                                  prop: "passPlateReliabilityThreshold",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: {
                                    width: "60px",
                                    margin: "0 5px 0 0",
                                  },
                                  attrs: { maxlength: "600" },
                                  on: {
                                    input: ($event) =>
                                      _vm.timeNumber(
                                        $event,
                                        "passPlateReliabilityThreshold"
                                      ),
                                  },
                                  model: {
                                    value:
                                      _vm.form.passPlateReliabilityThreshold,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "passPlateReliabilityThreshold",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.passPlateReliabilityThreshold",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 10 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "折返事件: 事件置信度≥",
                                  prop: "backEventReliabilityThreshold",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: {
                                    width: "60px",
                                    margin: "0 5px 0 0",
                                  },
                                  attrs: { maxlength: "600" },
                                  on: {
                                    input: ($event) =>
                                      _vm.timeNumber(
                                        $event,
                                        "backEventReliabilityThreshold"
                                      ),
                                  },
                                  model: {
                                    value:
                                      _vm.form.backEventReliabilityThreshold,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "backEventReliabilityThreshold",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.backEventReliabilityThreshold",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 10 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "车牌置信度≥",
                                  prop: "backPlateReliabilityThreshold",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: {
                                    width: "60px",
                                    margin: "0 5px 0 0",
                                  },
                                  attrs: { maxlength: "600" },
                                  on: {
                                    input: ($event) =>
                                      _vm.timeNumber(
                                        $event,
                                        "backPlateReliabilityThreshold"
                                      ),
                                  },
                                  model: {
                                    value:
                                      _vm.form.backPlateReliabilityThreshold,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "backPlateReliabilityThreshold",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.backPlateReliabilityThreshold",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
                _c(
                  "el-col",
                  { attrs: { span: 14 } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { display: "inline-block" },
                                attrs: {
                                  label: "支持相似车牌出入场匹配",
                                  prop: "similarPlateMatch",
                                },
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    on: {
                                      change: function ($event) {
                                        _vm.form.defaultProvince = ""
                                      },
                                    },
                                    model: {
                                      value: _vm.form.similarPlateMatch,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "similarPlateMatch",
                                          $$v
                                        )
                                      },
                                      expression: "form.similarPlateMatch",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: 1 } }, [
                                      _vm._v("是"),
                                    ]),
                                    _c("el-radio", { attrs: { label: 0 } }, [
                                      _vm._v("否"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.form.similarPlateMatch === 1 &&
                            _vm.details.parkAccessType == 3
                              ? _c(
                                  "el-form-item",
                                  {
                                    staticStyle: {
                                      display: "inline-block",
                                      "margin-left": "10px",
                                      width: "190px",
                                    },
                                    attrs: {
                                      "label-width": "90px",
                                      label: "默认省份",
                                      prop: "defaultProvince",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: { placeholder: "请选择" },
                                        model: {
                                          value: _vm.form.defaultProvince,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "defaultProvince",
                                              $$v
                                            )
                                          },
                                          expression: "form.defaultProvince",
                                        },
                                      },
                                      _vm._l(
                                        _vm.mrsfList,
                                        function (ite, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: ite.value,
                                              value: ite.key,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "军警车是否计费",
                          prop: "policeCarFree",
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.form.policeCarFree,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "policeCarFree", $$v)
                              },
                              expression: "form.policeCarFree",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("是"),
                            ]),
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("否"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    0
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "断网时是否允许车辆欠费离场",
                              prop: "noNetworkDeparture",
                            },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.form.noNetworkDeparture,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "noNetworkDeparture",
                                      $$v
                                    )
                                  },
                                  expression: "form.noNetworkDeparture",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 2 } }, [
                                  _vm._v("是"),
                                ]),
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("否"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.parkingSystemType
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "场内缴费后免费离场时间",
                              prop: "innerPayFreeLeaveTime",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: {
                                width: "120px",
                                margin: "0 5px 0 0",
                              },
                              attrs: { maxlength: 3 },
                              model: {
                                value: _vm.form.innerPayFreeLeaveTime,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "innerPayFreeLeaveTime",
                                    $$v
                                  )
                                },
                                expression: "form.innerPayFreeLeaveTime",
                              },
                            }),
                            _vm._v("分钟 "),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.parkingSystemType
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "记录缺失收费区域偏向",
                              prop: "childParkId",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                model: {
                                  value: _vm.form.childParkId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "childParkId", $$v)
                                  },
                                  expression: "form.childParkId",
                                },
                              },
                              _vm._l(_vm.parkList, function (value) {
                                return _c("el-option", {
                                  key: value.parkId,
                                  attrs: {
                                    label: value.parkName,
                                    value: value.parkId,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "itemWidth",
                        attrs: {
                          label: "机器人事件是否人工核实",
                          prop: "cameraEventManualConfirm",
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.form.cameraEventManualConfirm,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "cameraEventManualConfirm",
                                  $$v
                                )
                              },
                              expression: "form.cameraEventManualConfirm",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("是"),
                            ]),
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("否"),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "tooltip1",
                            staticStyle: { top: "10px", left: "230px" },
                            attrs: { placement: "top" },
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [
                                _vm._v(
                                  " 对已购买车道感知机器人的车场生效，选择否则自动忽略图片欠佳的情况，选择是则需人工 "
                                ),
                              ]
                            ),
                            _c("span", { staticClass: "help1" }, [_vm._v("?")]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "itemWidth",
                        attrs: {
                          label: "是否自动补发抬杆指令",
                          prop: "autoOpen",
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.form.autoOpen,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "autoOpen", $$v)
                              },
                              expression: "form.autoOpen",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("是"),
                            ]),
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("否"),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "tooltip1",
                            staticStyle: { top: "10px", left: "230px" },
                            attrs: { placement: "top" },
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [
                                _vm._v(
                                  " 下发抬杆指令，若闸机无反应或未完全抬起，系统自动再次发送抬杆命令，确保闸机正常抬起 "
                                ),
                              ]
                            ),
                            _c("span", { staticClass: "help1" }, [_vm._v("?")]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "itemWidth",
                        attrs: { label: "是否自动落杆", prop: "autoClose" },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.form.autoClose,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "autoClose", $$v)
                              },
                              expression: "form.autoClose",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("是"),
                            ]),
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("否"),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "tooltip1",
                            staticStyle: { top: "10px", left: "230px" },
                            attrs: { placement: "top" },
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [
                                _vm._v(
                                  " 闸机意外抬起且车道无车时，系统自动下发落杆指令，保障停车场安全 "
                                ),
                              ]
                            ),
                            _c("span", { staticClass: "help1" }, [_vm._v("?")]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              { attrs: { type: "flex", justify: "center" } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 14 } },
                  [
                    _c(
                      "el-form-item",
                      [
                        _vm.details.parkAccessType != 3
                          ? [
                              _vm.syncStatus == 2 &&
                              _vm.authority.button.status &&
                              _vm.parkingSystemType != 0
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "danger",
                                        plain: "",
                                        loading: _vm.updateLoading,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.updateData()
                                        },
                                      },
                                    },
                                    [_vm._v("同步失败")]
                                  )
                                : _vm._e(),
                              _vm.syncStatus == 1 &&
                              _vm.authority.button.status &&
                              _vm.parkingSystemType != 0
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        plain: "",
                                        loading: _vm.updateLoading,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.updateData()
                                        },
                                      },
                                    },
                                    [_vm._v("同步成功")]
                                  )
                                : _vm._e(),
                              _vm.authority.button.addSync
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "0 0 0 25px" },
                                      attrs: {
                                        type: "primary",
                                        loading: _vm.saveLoading,
                                      },
                                      on: { click: _vm.save },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.parkingSystemType != 0
                                            ? "保存并同步"
                                            : "保存"
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          : [
                              _vm.authority.button.addSync
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "0 0 0 25px" },
                                      attrs: {
                                        type: "primary",
                                        loading: _vm.saveLoading,
                                      },
                                      on: { click: _vm.save },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("button.preservation"))
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "title" }, [
      _c("div", { staticClass: "title_icon" }),
      _vm._v(" 出入场配置 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }